import { Toast } from 'vant'


export function errMessage (message) {
  Toast(message)
}


export function getQueryString (name) {
  let href = window.location.href.split('#')[0]
  var reg = new RegExp("(\\?|&)" + name + "=([^&]*)(&|$)", "i");
  var r = href.substr(1).match(reg);
  if (r != null) return (r[2]);
  return null;
}