import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { getQueryString } from '@/assets/js/utils'

import Vant from 'vant';
import '@/assets/lib/index.less';

Vue.use(Vant);



let token = getQueryString('token') || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzU1NjU3MzQsInVzZXJuYW1lIjoidGVzdDIifQ.LnpPCm32UqfhveMnjRq21G4cqvf66F6jXfQhgU8aiK8'
store.commit('setToken', token)

let url = getQueryString('url')

if (url) {
  url = decodeURIComponent(url)
  window.HTTP_URL = url
  console.log(url)
}



import axios from '@/assets/js/axios'

Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
