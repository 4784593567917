<template>
  <div :class="{fullscreen:fullScreen}" @click="resetPlay" class="mask">
    <div @click.stop="()=>{}" class="contents" ref="wrapper">
      <div :id="id" class="video-wrapper"  v-if="id"></div>
    </div>
  </div>
</template>
<script>
let id = 0
import EZUIKit from '@/assets/js/ezuikit'
export default {
  props: {
    video: {
      type: Object,
      require: true,
    },
    template:{
      type: String,
      default: 'simple',
    }
  },
  data() {
    return {
      id: null,
      fullScreen: false,
    }
  },
  created() {
    id++
    this.id = 'video-container-' + id
  },
  mounted() {
    this.width = this.$refs.wrapper.offsetWidth
    this.height = this.$refs.wrapper.offsetHeight

    // console.log(this.width, this.height)
    this.createPlayer()
  },
  beforeDestroy() {
    if (this.player) {
      this.player = null
    }
    this.$refs.wrapper.innerHTML = ''
  },
  methods: {
    createPlayer() {
      if (this.video.type === 2) {
        // 大华
        this.createImou()
      } else {
        // 海康
        this.createEZUIKit()
      }
    },
    createImou() {
      this.width = this.$refs.wrapper.offsetWidth
      this.height = this.$refs.wrapper.offsetHeight

      let url = this.video.url
      let token = this.video.kitToken

      this.player = new ImouPlayer('#' + this.id)

      this.player.setup({
        src: [
          {
            url: url,
            kitToken: token,
          },
        ],
        width: this.width,
        height: this.height,
        autoplay: true,
        controls: false,
      })
    },
    createEZUIKit() {
      let url = this.video.url.replace(/&.+$/, '')
      let token = this.video.kitToken
      this.player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: this.id,
        accessToken: token,
        url: url,
        template: this.template, // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        openSoundCallBack: data => console.log("开启声音回调", data),
        closeSoundCallBack: data => console.log("关闭声音回调", data),
        startSaveCallBack: data => console.log("开始录像回调", data),
        stopSaveCallBack: data => console.log("录像回调", data),
        capturePictureCallBack: data => console.log("截图成功回调", data),
        fullScreenCallBack: data => console.log("全屏回调", data),
        getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        width: this.width,
        height: this.height,
      })
    },
    resetPlay() {
      this.fullPagePlay(false)
    },
    fullPagePlay(flg) {
      // if (this.player) {
      //   this.player.fullScreen()
      // }

      this.fullScreen = !!flg

      if (this.video.type === 2) {
        setTimeout(() => {
          if (this.player) {
            this.player.destroy()
          }

          this.createImou()
        }, 100)
        // if (flg) {
        //   this.player.setFullScreen()
        // } else {
        //   this.player.exitFullScreen()
        // }
      } else {
        setTimeout(() => {
          if (this.player) {
            this.player.autoResize()
          }
        }, 100)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.mask {
  width: 100%;
  height: 100%;
  .contents {
    width: 100%;
    height: 100%;
  }
  .video-wrapper {
    width: 100%;
    height: 100%;
  }
  &.fullscreen {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba($color: #000000, $alpha: 0.7);
    display: flex;
    align-items: center;

    .contents {
      width: 100%;
      height: 50vw;
      transform: scale(1.5) rotate(90deg)
      // transform: rotate(90deg);
      // transform-origin: center;
    }
  }
}
</style>