<template>
<div>
  <div class="home" v-if="options1.length > 0">
    <!-- <van-nav-bar left-arrow @click-left="back" title="视频监控"> -->
      <!-- <template #right>
        <van-icon name="wap-nav" size="18" v-if="showChoose===false" @click="showChoose=true"/>
        <van-icon name="cross" size="18" v-else @click="showChoose=false"/>

      </template> -->
    <!-- </van-nav-bar> -->
    <!-- 下拉菜单 -->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        ref="menu1"
        v-model="value1"
        @change="change1"
        :options="options1"
      />
      <van-dropdown-item
        ref="menu2"
        v-model="value2"
        @change="change2"
        :options="options2"
      />
      <van-dropdown-item
        ref="menu3"
        v-model="value3"
        @change="change3"
        :options="options3"
      />
    </van-dropdown-menu>
    <!-- <van-dropdown-menu>
      <van-dropdown-item :options="option" v-model="value" />
    </van-dropdown-menu> -->
    <div class="video-list" v-if="p">
      <div class="video_box">
        <video-player :video="playInfo" template="simple" ref="player" v-if="p" />
      </div>
      <div class="video_title" v-if="p">
        <span class="name">{{ playInfo.name }}</span>
        <van-icon name="expand-o" size="20" @click="fullPagePlay()"/>
        <!-- <div class="fullIcon" @click="fullPagePlay()" ></div> -->
      </div>
      <!-- <div :key="index" class="video-item" v-for="(video, index) in videoList">
        <div class="frame">
          <div class="playIcon" v-if="index === playIndex">
            <div class="dot"></div>
            正在直播
          </div>
          <div
            class="fullIcon"
            @click="fullPagePlay(index)"
            v-if="index === playIndex"
          ></div>
          <img
            :src="httpIp + video.previewImg"
            alt=""
            v-if="index !== playIndex"
            style="position: absolute; width: 100%; height: 100%"
          />
          <video-player
            :video="playInfo"
            ref="player"
            v-if="p"
          />
          <div
            @click="playIndex = index"
            class="icon"
            v-else-if="video.isOnline === 1"
          ></div>
          <div class="off-line" v-else></div>
        </div>
        <div class="item">
          <span class="name">{{ video.name }}</span>
          <div @click="fullPagePlay(index)" class="fullpage"></div>
        </div>
      </div> -->
    </div>
    <!-- <van-popup
      v-model="showChoose"
      position="right"
      :style="{ height: 'calc(100% - 46px)', width: '70%' }"
    >
      <div style="padding: 0 10px">
        <div
          v-for="(item, index) in option"
          :key="index"
          @click="confirmOption(item.id)"
        >
          <div :class="value === item.id ? 'choosedItem' : 'chooseitem'">
            <div :class="value === item.id ? 'leftNamed' : 'leftName'">
              {{ item.text }}
            </div>
            <div :class="value === item.id ? 'rightNumd' : 'rightNum'">
              {{ item.count || 0 }}个监控
            </div>
          </div>
        </div>
      </div>
    </van-popup> -->
  </div>
  <van-loading style="margin-top:40px;" size="24px" vertical v-else>加载中...</van-loading>
</div>
</template>

<script>
// @ is an alias to /src
import VideoPlayer from "@/components/VideoPlayer";
import env from "@/assets/env";

export default {
  name: "Home",
  components: { VideoPlayer },
  data() {
    return {
      value1: 0,
      value2: "a",
      value3: "a",
      options1: [
        // { text: "全部商品", value: 0 },
        // { text: "新款商品", value: 1 },
        // { text: "活动商品", value: 2 },
      ],
      options2: [
        // { text: "默认排序", value: "a" },
        // { text: "好评排序", value: "b" },
        // { text: "销量排序", value: "c" },
      ],
      options3: [
        // { text: "默认排序", value: "a" },
        // { text: "好评排序", value: "b" },
        // { text: "销量排序", value: "c" },
      ],
      playInfo: {},
      p: false,

      option: [],
      value: null,
      videoList: [],
      playIndex: null,
      showChoose: false,
      httpIp: "",
    };
  },
  watch: {
    value(val) {
      if (val) {
        // this.getVideoList()
      }
    },
  },
  created() {
    this.httpIp = window.HTTP_URL || env.url;
    this.getbatchingPlant();
  },
  methods: {
    change1(e) {
      let item = this.options1.find((t) => {
        return t.value == e;
      });
      // console.log(item)
      this.options2 = item.children;
      setTimeout(() => {
        this.$refs.menu2.toggle();
      }, 400);
    },
    change2(e) {
      let item = this.options2.find((t) => {
        return t.value == e;
      });
      // console.log(item);
      this.options3 = item.children;
      setTimeout(() => {
        this.$refs.menu3.toggle();
      }, 400);
    },
    change3(e) {
      this.playInfo = {};
      this.p = false;
      let item = this.options3.find((t) => {
        return t.value == e;
      });
      setTimeout(() => {
        this.playInfo = item;
        this.p = true;
        console.log(this.playInfo);
      }, 10);
    },
    back() {
      if (window.uni) {
        uni.navigateBack();
      }
    },
    getbatchingPlant() {
      const url = "admin/camera/selectCameraList";
      let data = {};
      this.$http.getUrl(url, data).then((res) => {
        // console.log(res)
        this.options1 = this.changeList(res.result);
        console.log(this.options1);
        this.options2 = this.options1[0].children;
        this.options3 = this.options2[0].children;
        this.playInfo = this.options3[0] || {};
        this.p = true;
        this.value1 = this.options1[0].id;
        this.value2 = this.options2[0].id;
        this.value3 = this.options3[0].id;
      });
    },
    changeList(list) {
      let arr = [];
      arr = list.map((t) => {
        let children = [];
        if (t.children) {
          children = this.changeList(t.children);
        }
        return {
          ...t,
          value: t.id,
          text: t.name,
          children,
        };
      });
      return arr;
    },
    fullPagePlay() {
      // if (index !== this.playIndex) return;
      let player = this.$refs.player; // 永远一个
      if (player) {
        player.fullPagePlay(true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100vh;
  background-color: #f5f4f4;
}
.home ::v-deep .van-nav-bar__content {
  width: 100%;
}
::v-deep .van-overlay {
  margin-top: 44px;
}
// ::v-deep .van-popup {
//   margin-top: 22px;
// }
.chooseitem {
  margin: 10px 0;
  height: 42px;
  padding: 0 10px;
  line-height: 42px;
  border-radius: 5px;
  background: #fff;
  .leftName {
    float: left;
    color: #666666;
    font-size: 14px;
    font-weight: bold;
  }
  .rightNum {
    float: right;
    color: #9a9a9a;
    font-size: 12px;
  }
}
.choosedItem {
  margin: 10px 0;
  height: 42px;
  padding: 0 10px;
  line-height: 42px;
  border-radius: 5px;
  background: #3280ff;
  .leftNamed {
    float: left;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .rightNumd {
    float: right;
    color: #ffffff;
    font-size: 12px;
  }
}
.video-list {
  width: 93%;
  padding: 10px;
  box-sizing: border-box;
  margin: 15px auto;
  border-radius: 7px;
  overflow: hidden;
  background-color: #ffffff;
  .video_box {
    width: 100%;
    height: 280px;
    border-radius: 14rpx;
    overflow: hidden;
    // border: 2rpx solid #000000;
  }

  .video_title {
    color: #666;
    font-size: 32rpx;
    font-weight: 600;
    padding: 5px 7px 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fullIcon{
        // position: absolute;
        // bottom: 5px;
        // right: 5px;
        width: 19px;
        height: 19px;
        background-image: url('~@/assets/image/fullpage.png');
        background-size: 100% 100%;
        z-index: 100;
      }
  }
}
</style>