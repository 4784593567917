import axios from 'axios'
import qs from 'qs'
import env from '@/assets/env'
import store from '@/store/index'
// import res from '@/assets/result-model'
import {
  errMessage,
  // getItem
} from '@/assets/js/utils'
const SUCCESS_CODE = 200

let baseAxios = axios.create({
  // baseURL: env.url,
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded'
  // }
})

// let __token = null
let __http_one_only = false

// 发送信息钩子
baseAxios.interceptors.request.use(
  function (config) {
    let token = store.getters.token

    if (token) {
      config.headers['X-Access-Token'] = token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 返回消息钩子
baseAxios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

baseAxios.getUrl = (url, params = {}, {
  showErrflag = true
} = {}) => {
  let href = window.HTTP_URL || env.url
  return new Promise((resolve, reject) => {
    baseAxios
      .get(href + url, {
        params
      })
      .then(
        response => {
          let retData = response.data
          if (retData.code !== SUCCESS_CODE) {
            console.warn(url, params, retData)
          }
          retData.isok = (retData.code === SUCCESS_CODE)
          if (showErrflag) {
            ifErr(retData)
          }

          if (retData.isok) {
            resolve(retData)
          } else {
            reject(retData)
          }
        },
        error => {
          let res = error.response

          console.warn(error, res)
          // debugger
          if (res && res.data) {
            if (res.data.status === 500 && res.data.message.includes('Token失效')) {
              // store.dispatch('logout')
              errMessage('token过期')
              return
            }
          }

          errMessage('服务器错误')
          reject(error)
        }
      )
  })
}


baseAxios.postUrl = (url, data = {}, {
  showErrflag = true,
  postOne = false,
  json = true
} = {}) => {
  let href = window.HTTP_URL || env.url
  let headers = {}
  let opts = null

  if (Object.prototype.toString.call(data) !== '[object FormData]') {
    opts = {
      ...data,
    }
    if (!json) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
      opts = qs.stringify(opts)
    }

  } else { // formData对象一律认为是要发送流文件
    headers['Content-Type'] = 'multipart/form-data'
  }

  if (postOne) {
    // 已经有请求存在
    if (__http_one_only) {
      errMessage('请求中,请稍后...')
      return
    }
    __http_one_only = true
  }

  return new Promise((resolve, reject) => {
    baseAxios
      .post(href + url, opts, {
        headers
      })
      .then(
        response => {
          if (postOne) {
            __http_one_only = false
          }
          let retData = response.data

          retData.isok = (retData.code === SUCCESS_CODE)

          if (!retData.isok) {
            console.warn(url, data, retData)
          }

          if (showErrflag) {
            ifErr(retData)
          }
          if (retData.isok) {
            resolve(retData)
          } else {
            reject(retData)
          }

        },
        error => {
          if (postOne) {
            __http_one_only = false
          }
          let res = error.response

          console.warn(error, res)
          // debugger
          if (res.data) {
            if (res.data.status === 500 && res.data.message.includes('Token失效')) {
              // store.dispatch('logout')
              return
            }
          }
          errMessage(res.data.message)
          let errData = res.error('服务器错误', -1, error)
          reject(errData)
        }
      )
  })
}

function ifErr (retData) {
  if (!retData.isok) {
    errMessage(retData.message)
  }
}

export default baseAxios
