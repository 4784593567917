// import axios from '@/assets/js/axios.js'
// import utils from '@/assets/js/utils.js'

const state = {
  token: null
}
const getters = {
  token: state => state.token
}
const mutations = {
  setToken (state, token) {
    state.token = token
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
